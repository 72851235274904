import LogRocket from "logrocket";
import moment from "moment";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { nest, withProps } from "recompose";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ApolloProvider } from "@apollo/client";

import AuthContext, { AuthContextProvider } from "./auth/context.tsx";
import { apolloClient } from "./common/graphql/apollo-client.ts";
import ProgressProvider from "./common/progress/progress-provider.tsx";
import ToastProvider from "./common/toast/toast-provider.tsx";
import { GroupContextProvider } from "./packages/group/group-context.tsx";
import { store } from "./reducer/store";
import { RouterContextProvider } from "./router/context.tsx";
import routes from "./router/routes.tsx";
import { theme } from "./theme/mui-theme.tsx";

import "dayjs/locale/ko";
import "moment/locale/ko";
import "./global.css";
import "./fonts.css";

import dayjs from "dayjs";
const el = document.createElement("div", { id: "eruda" });
document.body.appendChild(el);

const Provider = nest(
  withProps({ store })(ReduxProvider),
  withProps({ theme })(SCThemeProvider),
  withProps({ theme })(MuiThemeProvider),
  withProps({ client: apolloClient })(ApolloProvider),
  withProps({ routes })(RouterContextProvider),
  AuthContextProvider,
  BrowserRouter,
  ProgressProvider,
  ToastProvider,
  withProps({ adapterLocale: "ko", dateAdapter: AdapterDayjs })(
    LocalizationProvider
  )
);

dayjs.locale("ko");
moment.locale("ko");
if (process.env.NODE_ENV === "production") {
  LogRocket.init("zxsoci/binding");
}

const RootElement = () => {
  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Provider>
      <LocalizationProvider
        dateFormats={{
          meridiem: "AM",
        }}
      />
      <AuthContext.Consumer>
        {({ user, nonUserParticipant }) => {
          if (user) {
            return <GroupContextProvider>{routes.user}</GroupContextProvider>;
          }

          if (nonUserParticipant) {
            return routes.nonUserParticipant;
          }

          return routes.anonymous;
        }}
      </AuthContext.Consumer>
    </Provider>
  );
};

ReactDOM.render(<RootElement />, document.getElementById("root"));
